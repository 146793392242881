<!--
  FormRow.vue
  Component: FormRow

  Used to display forms built with Drupals Webform module.

  @TODO: Implement every possible field
-->
<template>
  <div class="form-row"></div>
</template>

<script>
// Export FormRow as Vue object.
export default {
  // The components name.
  name: "FormRow",
  // Properties passed as HTML attributes must be registered here.
  // Schema:
  // <code>
  // {
  //   attributeName: dataType (e.g. "Object" or "String" (without quotes))
  // }
  // </code>
  props: {
    // The "data" object will hold every setting and content fetched from the api.
    data: Object
  }
};
</script>
