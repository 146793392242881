<!--
  SliderRow.vue
  Component: SliderRow

  Used to display a carousel built with multiple images.
  This component is used when the API passed "slider" as content row type.

  Basically this is a modification of ImageRow that adds the ability to handle multiple images. Because of that the
  documentation is limited to the different parts only.
-->
<template>
  <div class="image-row" :style="style">
    <div class="image-row-inner" :class="backgroundColor" :style="innerStyle">
      <!--
        Initializes the slider.

        Attributes:
        - interval {Number}: Sets the interval in milliseconds in which the next slide should be displayed.
        - controls (good to know, no need to edit): Let the slider display arrow buttons so that users can slide
          manually.
        - indicators (good to know, no need to edit): Let the slider display those sneaky little indicators at the
          bottom of the slider so that users know how many images we are showing and which one they currently see.
      -->
      <b-carousel :interval="5000" controls indicators class="slider-row-content mx-auto" :class="width">
        <!--
          The actual single slide.

          This basically just provides the for loop to iterate over the images.
          Every single image will then be a <b-carousel-slide>-Element.
        -->
        <b-carousel-slide v-for="(image, index) in images" :key="index">
          <!--
            Inside of the BCarouselSlide component there is a <img>-Element. To manipulate that we need to add a
            <template>-Element with the v-slot:img-directive. That allows us to insert a custom <img>-Element insice
            <b-carousel-slide>.

            @see node_modules/bootstrap-vue/src/components/carousel/carousel-slide.js
          -->
          <template v-slot:img>
            <img :src="image.source" :alt="image.alternativeText" class="d-block img-fluid" />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import { Etagen } from "@/etagen";
export default {
  name: "SliderRow",
  props: {
    data: Object
  },
  data() {
    return {
      backgroundImage: "",
      backgroundColor: "",
      width: "",
      style: "",
      innerStyle: "",
      contentStyle: "",
      images: []
    };
  },
  created() {
    if (this.data.backgroundColor !== undefined) {
      // this.backgroundColor = Etagen.renderer.setBackgroundColor(this.data.backgroundColor);
      this.backgroundColor = "bg-black";
    }

    if (this.data.backgroundImage !== undefined) {
      this.backgroundImage = Etagen.renderer.buildBackgroundImageStyle(this.data.backgroundImage.url);
      this.style += this.backgroundImage;
      this.backgroundColor = Etagen.renderer.getTransparentBackgroundColor(this.backgroundColor);
    }

    if (this.data.width !== undefined) {
      this.width = Etagen.renderer.setContentWidth(this.data.width);
    }

    if (this.data.images !== undefined) {
      // Since we want to display multiple images we need to iterate over every single one.
      // The logic inside the for loop is copied from ImageRow.
      for (let i = 0; i < this.data.images.length; i++) {
        let img = {};
        img.source = this.data.images[i].images[0].url;
        if (this.data.layout !== undefined) {
          let layout = this.data.layout;
          let styles = this.data.images[i].images[0].styles;
          switch (layout) {
            case "7_2":
              if (styles.slider_7_2 !== undefined) {
                img.source = styles.slider_7_2.url;
              }
              break;
            default:
              if (styles[layout] !== undefined) {
                img.source = styles[layout];
              }
              break;
          }
        }
        img.alternativeText = this.data.images[0].alt;

        this.images.push(img);
      }
    }
  }
};
</script>
