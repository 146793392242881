<template>
  <div class="news-slider">
    <div id="splide-news-slider" class="splide">
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">
          <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 28 15.3"
            style="enable-background:new 0 0 28 15.3;"
            xml:space="preserve"
          >
            <g id="Gruppe_455" transform="translate(-947.5 -911.793)">
              <rect x="947.5" y="918.5" width="25.5" height="2" />
              <polygon points="967.8,927.1 966.4,925.7 972.6,919.5 966.4,913.2 967.8,911.8 975.5,919.5 	" />
            </g>
          </svg>
        </button>
        <button class="splide__arrow splide__arrow--next">
          <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 28 15.3"
            style="enable-background:new 0 0 28 15.3;"
            xml:space="preserve"
          >
            >
            <g id="Gruppe_455" transform="translate(-947.5 -911.793)">
              <rect x="947.5" y="918.5" width="25.5" height="2" />
              <polygon points="967.8,927.1 966.4,925.7 972.6,919.5 966.4,913.2 967.8,911.8 975.5,919.5 	" />
            </g>
          </svg>
        </button>
      </div>
      <div class="splide__track">
        <ul class="splide__list">
          <li v-for="(article, articleKey) in news" :key="articleKey" class="splide__slide">
            <a :href="article.path" class="slide">
              <div v-lazyload class="image">
                <img :data-url="`${article.teaserImage}`" alt="">
              </div>
              <div class="article-teaser text-white">
                <h4 class="title">
                  <b>{{ article.title }}</b>
                </h4>
                <div class="text">
                  <p v-html="article.teaserText"></p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Splide from "@splidejs/splide";
import Grid from "@splidejs/splide-extension-grid";

export default {
  name: "NewsSlider",
  props: {
    news: Array
  },
  created() {
    window.setTimeout(() => {
      new Splide("#splide-news-slider", {
        type: "loop",
        perPage: 4,
        perMove: 1,
        arrowPath: "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 3
          },
          860: {
            perPage: 2
          },
          550:{
            perPage: 1
          }
        }
      }).mount({ Grid });
    }, 100);
  }
};
</script>

<style scoped lang="scss">
.splide__slide {
  max-height: 29em;
  min-height: 29em;
  padding-right: 1rem;
  .slide {
    display: block;
    position: relative;
    height: 100%;
    .image{
      filter: grayscale(100%);
      transition: all 0.2s;
      height: 100%;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s ease-in-out;
    }
    .article-teaser{
      position: absolute;
      bottom: 0;
      padding: 1em;
      .text{
        transform: translate3d(0, 40px, 0);
        color: rgba(255, 255, 255, 0.8);
        max-height: 0;
        transition: max-height 0.2s,padding-bottom 0.2s, transform 0.35s;
        opacity: 0;
      }
      h4{
        color: #ffffff;
      }
    }
    &:hover{
      .image{
        filter: grayscale(0);
        img{
          transform: scale3d(1.1, 1.1, 1);
        }
      }
      .text{
        max-height: 14.5rem;
        transform: translate3d(0, 0, 0);
        transition-delay: 0.05s;
        transition-duration: 0.35s;
        opacity: 1;
      }
    }
  }
}

.splide__arrow--prev{
  left: 1.5em;
}
.splide__arrow--next {
  right: 2.2em;
}
</style>
