<template>
  <div class="contact-form row ">
    <div v-if="isJobs" id="jobs"></div>
    <div v-if="contactPerson" class="contact-persons col-sm-6">
      <div class="card">
      <b-card-img-lazy :src="contactPerson.imageUrl" :alt="contactPerson.name"></b-card-img-lazy>
      <b-card
        :title="contactPerson.name"
        :sub-title="contactPerson.position"
      ></b-card>
      </div>
    </div>
    <div class="contact-formular col-sm-6">
      <h2 class="primary-color"><span class="yellow primary-color xbold">interessiert?</span><br><span class="light-color xbold">schreiben </span> sie mir </h2>
      <a @click="conversation" class="light-color tel" href="tel:05416001550"><span class="warning-color">T: &emsp;</span>0541&nbsp;6001550</a>
      <a class="light-color" href="mailto:info@die-etagen.de"><span class="warning-color">E: &emsp;</span>info@die-etagen.de</a><br />
      <ContactForm :to="contactPerson.email" :to-name="contactPerson.name"></ContactForm>
    </div>
  </div>
</template>
<script>
import ContactForm from "@/components/ContactForm";

export default {
  name: "ContactPerson",
  components: { ContactForm },
  props: {
    contactPerson: Object
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: ""
      },
      show: true,
      endpoint: ""
    };
  },
  methods: {
    conversation (url) {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      this.$gtag.event("conversion", {
        send_to: "AW-879092262/7CpCCMmwioADEKbEl6MD",
        event_callback: callback
      });
      return false;
    }
  },
  computed: {
    isJobs() {
      return this.$route.path === "/jobs";
    }
  }
};
</script>

<style scoped lang="scss">
.contact-persons {
  padding-top: 6rem;
  display: flex;
  justify-content: center ;
  img {
    filter: grayscale(100%);
  }
}
#jobs{
  scroll-margin: 200px;
}
.contact-form{
  .contact-formular{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2{
      font-size: 3.2rem;
      padding: 2rem 0;
      text-transform: uppercase;
      text-align: left;
      span{
        font-weight: 800;
      }
    }
    a.light-color{
      text-decoration: none;
      width: fit-content;
      &:hover{
        color: #ffec41;
      }
    }
  }
}
.card{
  height: 100%;
  border-radius: unset;
  border: none;
  img{
    height: 100%;
    object-fit: cover;
    border-radius: unset;
    object-position: top;
  }
}
.card-body{
  position: absolute;
  bottom: 0;
  >*{
    color: #ffffff !important;
  }
}

@media screen and (max-width: 860px){
  .contact-formular{
    padding: 0 2.4rem 3rem;
    h2{
      font-size: 2.4rem;
    }
  }
}
@media screen and (max-width: 575px) {
  .contact-persons{
    padding: 0;
  }
}
@media (max-width: 450px){
  .card{
    max-height: 70vh;
    width: 100%;
  }
}
</style>
