<!--
  ImageRow.vue
  Component: ImageRow

  Used to display single images.
  If you are looking for sliders / carousels, have a look at ./SliderRow.vue.
-->
<template>
  <!--
    Wrapper element for the image row.

    Attributes:
    - style {String}: The style attribute will be calculated during rendering as this will hold an url to a background
      image if any is given at all. In general, you have the possibility to add, remove or edit single styles within the
      created() or mounted() methods implemented below as well as in one of the methods passed in this.methods.
  -->
  <div class="image-row" :style="style">
    <!--
      An inner wrapper for further styles.
      This was necessary because in certain defined cases we want to display an background image AND a background color.
      So we use this extra layer to display the background color above the background image.

      Attributes:
      - class {String}: By default the class attribute will receive a class which defines the background-color only. The
        class is mostly used from bootstrap or it is defined in one of the custom stylesheets.
      - style {String}: Like in div.image-row (one level above) you can define custom styles at runtime.
    -->
    <div v-lazyload class="image-row-inner" :style="innerStyle">
      <!--
        The actual image tag.
        This tag will display the actual image to be shown to the user.

        Attributes:
        - class {String}: The only class this item will get is the one that defines how big or small the image should be
          displayed. This depends on this.data.width which will be one of the two possible values "narrow" or "wide".
          Dependent on which value we got we will set "w-100" for "wide" and "w-75" for "narrow" (by default).
        - style {String}: Have a look at the two levels above, there is already enough said about this attribute.
        - src {String}: This attribute will be filled with a full URL to an image at runtime whenever this component is
          being imported/used.
        - alt {String}: Like src, this attribute will be filled with content on runtime.

        Classes to notice:
        - d-block: Sets the display value to be "block"
        - img-fluid: Sets the max-width to be 100% and the height to be "auto"
        - p-3: Adds a padding of 1rem to all directions
        - mx-auto: Adds "margin: 0 auto"
      -->
      <img
        class="image-row-content d-block img-fluid mx-auto"
        :class="width"
        :style="contentStyle"
        :data-url="source"
        :alt="alternativeText"
      />
    </div>
  </div>
</template>

<script>
// Import the Etagen oject for some nice rendering helper methods.
import { Etagen } from "@/etagen";

// Export the components object.
export default {
  // The name of the component.
  name: "ImageRow",
  // Properties passed as HTML attributes must be registered here.
  // Schema:
  // <code>
  // {
  //   attributeName: dataType (e.g. "Object" or "String" (without quotes))
  // }
  // </code>
  props: {
    data: Object
  },
  // The data() method returns an object which holds data that is not being "imported" as property but which is still
  // being used in the template above.
  data() {
    return {
      backgroundImage: "",
      backgroundColor: "",
      width: "",
      style: "",
      innerStyle: "",
      contentStyle: "",
      source: "",
      alternativeText: ""
    };
  },
  created() {
    // Set the background color defining class.
    if (this.data.backgroundColor !== undefined) {
      this.backgroundColor = Etagen.renderer.setBackgroundColor(this.data.backgroundColor);
    }

    // Set the style attribute to display the background image.
    if (this.data.backgroundImage !== undefined) {
      this.backgroundImage = Etagen.renderer.buildBackgroundImageStyle(this.data.backgroundImage.url);
      this.style += this.backgroundImage;
      this.backgroundColor = Etagen.renderer.getTransparentBackgroundColor(this.backgroundColor);
    }

    // Set the width defining class.
    if (this.data.width !== undefined) {
      this.width = Etagen.renderer.setContentWidth(this.data.width);
    }

    // Set the source URL of the image to display.
    if (typeof this.data.images[0].url != "undefined" || typeof this.data.images[0].uri != "undefined") {
      if (this.data.images[0].uri !== "undefined") {
        this.source = this.data.images[0].uri;
      } else {
        this.source = this.data.images[0].url;
      }
    } else {
      if (this.data.images[0].images[0].url !== "undefined") {
        this.source = this.data.images[0].images[0].url;
      } else {
        this.source = this.data.images[0].images[0].uri;
      }
    }

    // If the image should be displayed in a special layout (7:2 for example) we will read the field that holds the
    // value for this before we search for the image URL in the passed image styles.
    if (this.data.layout !== undefined) {
      // Read the layout.
      let layout = this.data.layout;

      // Read the image styles.
      let styles = this.data.images[0].styles ? this.data.images[0].styles : this.data.images[0].images[0].styles;
      switch (layout) {
        case "7_2":
          if (styles.slider_7_2 !== undefined) {
            this.source = styles.slider_7_2.url;
          }
          break;
        default:
          // If the needed style exists, we will use the image URL saved there.
          if (styles[layout] !== undefined) {
            this.source = styles[layout];
          }
          break;
      }
    }

    // Read the alternative text passed in this.data.
    this.alternativeText = this.data.images[0].alt;
  }
};
</script>
<style>
.carousel-control-prev-icon{
  background-image: url("../../assets/arrow_left.svg") !important;
}
.carousel-control-next-icon{
  background-image: url("../../assets/arrow_right.svg") !important;
}
</style>