<template>
  <div class="teaser-row" :style="style">
    <div
      class="teaser-row-inner"
      :class="`${backgroundColor}`"
      v-if="referenced !== undefined && (data.layout === 'grid' || data.layout === 'masonry')"
    >
      <b-card-group
        columns
        v-for="(reference, i) in referenced"
        :key="i"
        class="teaser-row-content mx-auto px-3"
        :class="`${width} column-count-${itemsPerRow}`"
      >
        <b-card v-for="(ref, index) in reference" :key="index" tag="article" class="col p-0 mb-3" :img-src="ref.image">
          <h4>{{ ref.title }}</h4>
          <b-card-text v-if="ref.text !== undefined" v-html="ref.text"></b-card-text>
          <b-button v-if="ref.url" :href="ref.url" variant="primary">Weiterlesen</b-button>
        </b-card>
      </b-card-group>
    </div>

    <div
      class="teaser-row-inner"
      v-if="referenced !== undefined && data.layout === 'slider'"
      :class="`${backgroundColor}`"
    >
      <b-carousel
        v-for="(reference, index) in referenced"
        :key="index"
        :interval="500000"
        controls
        indicators
        class="teaser-row-inner-inner mx-auto"
        :per-page="itemsPerRow"
        :perPage="itemsPerRow"
        :class="`${width}`"
      >
        <b-carousel-slide
          v-for="(ref, i) in reference"
          :key="i"
          class="teaser-row-content mx-auto px-3"
          :class="`column-count-${itemsPerRow}`"
          :img-src="ref.image"
          :caption-html="`<h4>${ref.title}</h4>`"
          :text-html="ref.text + `<br /><a class='btn btn-primary' href='${ref.url}'>Weiterlesen</a>`"
        >
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import { Etagen } from "@/etagen";

export default {
  name: "TeaserRow",
  props: {
    data: Object
  },
  data() {
    return {
      backgroundImage: "",
      backgroundColor: "",
      width: "",
      style: "",
      referenced: [],
      colWidth: "",
      itemsPerRow: 4,
      config: {
        useTeaserImageStyle: "max_650x650",
        truncateLongText: true,
        truncationLength: 200
      }
    };
  },
  created() {
    if (this.data.backgroundColor !== undefined) {
      this.backgroundColor = Etagen.renderer.setBackgroundColor(this.data.backgroundColor);
    }

    if (this.data.backgroundImage !== undefined) {
      this.backgroundImage = Etagen.renderer.buildBackgroundImageStyle(this.data.backgroundImage.url);
      this.style += this.backgroundImage;
      this.backgroundColor = Etagen.renderer.getTransparentBackgroundColor(this.backgroundColor);
    }

    if (this.data.width !== undefined) {
      this.width = Etagen.renderer.setContentWidth(this.data.width);
    }

    // Calculate which col class we need to use, dependent of the amount of how mayn items we want to display in a row.
    this.itemsPerRow = this.data.itemsPerRow;
    this.itemsPerRow = parseInt(this.itemsPerRow);
    let colWidth = 12 / this.itemsPerRow;
    this.colWidth = `col-${colWidth}`;
    this.referenced = Etagen.renderer.reworkTeasers(this.data.referenced, this.config);
    this.referenced = Etagen.renderer.reorderTeaser(this.data.layout, this.referenced, this.itemsPerRow);
  }
};
</script>

<style lang="scss">
@import "src/themes/default/index";
.carousel-caption {
  background-color: $dark-transparent;
}
</style>
