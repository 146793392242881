import axios from "axios";
import Config from "@/config";

export const JSONAPI = {
  fetchResource(
    entityType,
    entityBundle,
    tags,
    tagFieldName = "field_tags",
    fieldsToFetch = [],
    includes = [],
    multiTermOperator = "AND"
  ) {
    let tagFilter, fieldFilter, defaultMultipleOperator, include, jsonapiUrl;

    defaultMultipleOperator = multiTermOperator;

    // Set up the term filter.
    // We will first have a look at the parameter we got:
    // - if it's considered empty, we will leave the tagFilter empty
    // - if it's an array we will build the filter by iterating over the array
    // - if it's a simple string, we will just use that as is.
    tagFilter = "";
    if (tags instanceof Array && tags.length > 0) {
      tagFilter = `filter[tagsFilter][condition][path]=${tagFieldName}.name&filter[tagsFilter][condition][operator]=${defaultMultipleOperator}&`;
      tags.forEach(term => {
        tagFilter += `filter[tagsFilter][condition][value][]=${term}&`;
      });
    } else if (tags !== null && tags !== "") {
      tagFilter = `filter[tagsFilter][condition][path]=${tagFieldName}.name&filter[tagsFilter][condition][value]=${tags}&`;
    }

    if (fieldsToFetch instanceof Array && fieldsToFetch.length !== 0) {
      fieldFilter = `fields[${entityType}--${entityBundle}]=${fieldsToFetch.join(",")}&`;
    } else {
      fieldFilter = "";
    }

    if (includes instanceof Array && includes.length !== 0) {
      include = `include=${includes.join(",")}&`;
    } else {
      include = "";
    }

    let sort =
      "sort[sort-promote][path]=promote&sort[sort-promote][direction]=DESC&sort[sort-date][path]=created&sort[sort-date][direction]=DESC";

    jsonapiUrl = `${Config.api.base_url}/jsonapi/${entityType}/${entityBundle}?${tagFilter}${fieldFilter}${include}${sort}`;

    // Trim the last '&'.
    jsonapiUrl = jsonapiUrl.replace(/&$/g, "");

    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${Config.api.base_url}/session/token`,
        headers: {
          "Accept-Encoding": "deflate"
        }
      })
        .then(tokenResult => {
          if (tokenResult.data !== undefined) {
            let token = tokenResult.data;
            axios({
              method: "get",
              url: jsonapiUrl,
              headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/vnd.api+json",
                "Accept-Encoding": "deflate"
              }
            })
              .then(result => {
                resolve(result);
              })
              .catch(error => reject(error));
          } else {
            reject("The session token could not be fetched.");
          }
        })
        .catch(tokenError => reject(tokenError));
    });
  },
  fetchResourceAsTeaser(
    entityType,
    entityBundle,
    tags,
    groupResult = false,
    groupSize = 5,
    tagFieldName = "field_tags",
    fieldsToFetch = [],
    includes = [],
    teaserTextFieldName = "et_def_description",
    teaserImageFieldName = "et_def_image",
    imageStyleToUse = "max_2600x2600",
    multiTermOperator = "AND"
  ) {
    let references, group, rebuiltEntity;

    references = [];
    group = [];
    rebuiltEntity = {};

    return new Promise((resolve, reject) => {
      this.fetchResource(entityType, entityBundle, tags, tagFieldName, fieldsToFetch, includes, multiTermOperator)
        .then(result => {
          if (result.data !== undefined) {
            if (result.data.data !== undefined) {
              result.data.data.forEach(rawEntity => {
                rebuiltEntity = {
                  title: "",
                  path: "",
                  teaserText: "",
                  teaserImage: ""
                };

                // Find the reference title.
                if (rawEntity.attributes !== undefined && rawEntity.attributes.title !== undefined) {
                  rebuiltEntity.title = rawEntity.attributes.title;
                }

                // Determine the default drupal path (not the alias) that will always work.
                if (rawEntity.attributes !== undefined && rawEntity.attributes.drupal_internal__nid !== undefined) {
                  rebuiltEntity.path = `/node/${rawEntity.attributes.drupal_internal__nid}`;
                }

                // Find out if the node has an path alias we can use.
                if (
                  rawEntity.attributes !== undefined &&
                  rawEntity.attributes.path !== undefined &&
                  rawEntity.attributes.path.alias !== null
                ) {
                  rebuiltEntity.path = rawEntity.attributes.path.alias;
                }

                // Get the teaser text.
                if (
                  rawEntity.attributes !== undefined &&
                  rawEntity.attributes[teaserTextFieldName] !== undefined &&
                  rawEntity.attributes[teaserTextFieldName].value !== null
                ) {
                  rebuiltEntity.teaserText = rawEntity.attributes[teaserTextFieldName].value;
                }

                let relationships, teaserImage;

                // Get the teaser image.
                if (rawEntity.relationships !== undefined) {
                  relationships = rawEntity.relationships;
                  if (relationships[teaserImageFieldName] !== undefined) {
                    teaserImage = relationships[teaserImageFieldName];
                    if (teaserImage.data !== undefined) {
                      let media_uuid = teaserImage.data.id;
                      let media_type = teaserImage.data.type;

                      if (
                        result.data.included !== undefined &&
                        result.data.included instanceof Array &&
                        result.data.included.length > 0
                      ) {
                        result.data.included.forEach(include => {
                          if (include.type === media_type && include.id === media_uuid) {
                            if (include.relationships !== undefined) {
                              if (include.relationships.field_media_image !== undefined) {
                                let file_uuid = include.relationships.field_media_image.data.id;
                                let file_type = include.relationships.field_media_image.data.type;

                                result.data.included.forEach(incl => {
                                  if (incl.type === file_type && incl.id === file_uuid) {
                                    rebuiltEntity.teaserImage = `${Config.api.base_url}${incl.attributes.uri.url}`;

                                    if (incl.attributes.image_style_uri !== undefined) {
                                      let imageStyles = incl.attributes.image_style_uri;
                                      imageStyles.forEach(style => {
                                        if (style[imageStyleToUse] !== undefined) {
                                          rebuiltEntity.teaserImage = style[imageStyleToUse];
                                        }
                                      });
                                    }
                                  }
                                });
                              }
                            }
                          }
                        });
                      }
                    }
                  }
                }

                if (groupResult) {
                  group.push(rebuiltEntity);
                  if (group.length >= groupSize) {
                    references.push(group);
                    group = [];
                  }
                } else {
                  references.push(rebuiltEntity);
                }
              });

              if (groupResult) {
                references.push(group);
                group = [];
              }

              resolve(references);
            } else {
              reject(`No entities of type ${entityType} and bundle ${entityBundle} has been found.`);
            }
          } else {
            reject(`When fetching entities of type ${entityType} and bundle ${entityBundle}, no data has been sent.`);
          }
        })
        .catch(error => reject(error));
    });
  },
  fetchReferencesFilteredByTag(tag) {
    return new Promise((resolve, reject) => {
      this.fetchResourceAsTeaser(
        "node",
        "reference",
        tag,
        true,
        5,
        "field_tags",
        [],
        ["et_def_image.field_media_image"]
      )
        .then(result => {
          resolve(result);
        })
        .catch(error => reject(error));
    });
  },
  fetchArticlesFilteredByTag(tag) {
    return new Promise((resolve, reject) => {
      this.fetchResourceAsTeaser("node", "article", tag, false, 5, "field_tags", [], ["et_def_image.field_media_image"])
        .then(result => {
          resolve(result);
        })
        .catch(error => reject(error));
    });
  },
  fetchContactPersonFilteredByTag(tag) {
    return new Promise((resolve, reject) => {
      this.fetchResource("node", "contact_person", tag, "field_tags", [], ["field_contact_image"])
        .then(result => {
          let contactPerson = {
            email: "",
            imageUrl: "",
            name: "",
            position: ""
          };

          if (result.data !== undefined && result.data.data instanceof Array && result.data.data.length > 0) {
            let rawContactPerson = result.data.data[0];
            if (rawContactPerson.attributes !== undefined && rawContactPerson.attributes.title !== undefined) {
              contactPerson.name = rawContactPerson.attributes.title;
            }

            if (
              rawContactPerson.attributes !== undefined &&
              rawContactPerson.attributes.field_contact_mail !== undefined
            ) {
              contactPerson.email = rawContactPerson.attributes.field_contact_mail;
            }

            if (
              rawContactPerson.attributes !== undefined &&
              rawContactPerson.attributes.field_contact_title !== undefined
            ) {
              contactPerson.position = rawContactPerson.attributes.field_contact_title;
            }

            if (
              result.data.included !== undefined &&
              result.data.included instanceof Array &&
              result.data.included.length > 0
            ) {
              result.data.included.forEach(include => {
                if (
                  rawContactPerson.relationships !== undefined &&
                  rawContactPerson.relationships.field_contact_image !== undefined &&
                  rawContactPerson.relationships.field_contact_image.data !== undefined &&
                  include.attributes !== undefined &&
                  include.attributes.uri !== undefined &&
                  rawContactPerson.relationships.field_contact_image.data.id === include.id
                ) {
                  contactPerson.imageUrl = `${Config.api.base_url}${include.attributes.uri.url}`;
                }
              });
            }
          }

          resolve(contactPerson);
        })
        .catch(error => reject(error));
    });
  }
};
