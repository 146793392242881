<!--
  TextRow.vue
  Component: TextRow

  Used to display a single text which can but not must use the HTML syntax.
-->
<template>
  <!--
    Text wrapper element.

    Like other components, this container may get a background image during runtime.

    Attributes:
    - style {String}: The CSS declaration to display an background image.
  -->
  <div class="text-row" :style="style">
    <!--
      The inner wrapper element.

      Attributes:
      - class {String}: Like in other components, here may a CSS declaration to display a background color be inserted.
      - style {String}: Also borrowed from other components, in this attribute you can define custom inner styles to be
        rendered on runtime.
    -->
    <div class="text-row-inner" :style="innerStyle">
      <!--
        The element that contains the final text to display.

        Attributes:
        - class {String}: By default the width of the element will be set here.
        - style {String}: If needed, you can pass any custom styles here.
      -->
      <div class="text-row-content" v-html="content" :class="width" :style="contentStyle"></div>
    </div>
  </div>
</template>

<script>
import { Etagen } from "@/etagen";
import Config from "@/config";

export default {
  name: "TextRow",
  props: {
    data: Object
  },
  data() {
    return {
      backgroundImage: "",
      backgroundColor: "",
      width: "",
      style: "",
      innerStyle: "",
      contentStyle: "",
      content: ""
    };
  },
  methods: {
    imageUrl() {
      let textRow = document.querySelectorAll(".text-row-content img");
      textRow.forEach(element => {
        let src = element.getAttribute("src");
        if (src.match(/^\/sites/g)) {
          element.setAttribute("src", `${Config.api.base_url}${src}`);
        }
      });
    }
  },
  created() {
    if (this.data.backgroundColor !== undefined) {
      this.backgroundColor = Etagen.renderer.setBackgroundColor(this.data.backgroundColor);
    }

    if (this.data.backgroundImage !== undefined) {
      this.backgroundImage = Etagen.renderer.buildBackgroundImageStyle(this.data.backgroundImage.url);
      this.style += this.backgroundImage;
      this.backgroundColor = Etagen.renderer.getTransparentBackgroundColor(this.backgroundColor);
    }

    if (this.data.width !== undefined) {
      this.width = Etagen.renderer.setContentWidth(this.data.width);
    }

    if (typeof this.data.text != "undefined") {
      this.content = this.data.text;
    } else if (typeof this.data.description != "undefined") {
      this.content = this.data.description;
    }
    window.setInterval(() => {
      this.imageUrl();
    });
  }
};
</script>

<style scoped lang="scss">
.text-row {
  .bg-dark,
  .bg-dark-transparent {
    color: #eee;
  }
}
</style>
