<!--
  Content.vue
  Component: Content

  This component is being used as view component and actually handles every page request.
-->
<template>
  <div class="content taxonomy" v-if="content">
    <b-overlay :show="loading" rounded="false" variant="light" blur="50px">
      <!--
          For details about this component, have a look at ../components/content-rows/WrapperRowTaxonpmy.vue.
        -->
      <template v-if="content.field_taxonomy_content_text || content.field_taxonomy_content_textmedia">
        <div class="wrapper-row fp-section fp-table wrapper-padding d-flex flex-row top-content">
          <div class="wrapper-row-inner max-w row">
            <div v-if="content.field_taxonomy_content_text" class="col col-md-6 nsb-section">
              <div class="text-row">
                <div class="text-row-inner">
                  <div class="text-row-content mx-auto" v-html="content.field_taxonomy_content_text"></div>
                </div>
              </div>
            </div>

            <div
              v-for="(row, index) in content.field_taxonomy_content_textmedia"
              :key="index"
              class="wrapper-row-inner col-md-6"
            >
              <ImageRow :data="row"></ImageRow>
            </div>
          </div>
        </div>
      </template>

      <WrapperRow :is-root="true" :data="content"></WrapperRow>

      <!-- TODO PROJEKTE FEHLEN NOCH KOMPLETT -->
      <div
        class="nsb-section wrapper-row fp-section fp-table wrapper-padding d-flex flex-row references"
        v-if="references !== null"
      >
        <div class="wrapper-row-inner max-w">
          <h2 class="text-uppercase light-color">
            <span class="primary-color light-color xbold">WAS WIR SO TUN.</span>
          </h2>
          <ProjectSlider :projects="references"></ProjectSlider>
        </div>
      </div>

      <!-- TODO THEMEN UND NEWS FEHLEN NOCH KOMPLETT -->
      <div
        class="nsb-section wrapper-row fp-section fp-table wrapper-padding d-flex flex-row news"
        v-if="news !== null"
      >
        <div class="wrapper-row-inner max-w">
          <h2 class="text-uppercase">
            <span class="primary-color light-color xbold">WAS UNS BEWEGT.</span>
          </h2>
          <NewsSlider :news="news"></NewsSlider>
        </div>
      </div>

      <!-- TODO KONTAKT FEHLT NOCH KOMPLETT-->
      <div
        class="wrapper-row fp-section fp-table wrapper-padding  d-flex flex-row contact nsb-section"
        v-if="contactPerson !== null"
      >
        <div class="wrapper-row-inner max-w">
          <ContactPerson :contact-person="contactPerson"></ContactPerson>
        </div>
      </div>

      <div
        v-for="(row, index) in content.field_taxonomy_content_seo"
        :key="index"
        class="wrapper-row section fp-auto-height wrapper-padding d-flex flex-row seo nsb-section"
      >
        <div class="wrapper-row-inner max-w">
          <div class="col">
            <TextRow :data="row" :is-root="true"></TextRow>
          </div>
          <button @click="showSeoText" class="btn btn-gradient see-more"></button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { EtagenDrupalApi } from "@/etagen-api";
import TextRow from "../components/content-rows/TextRow";
import WrapperRow from "../components/content-rows/WrapperRow";
import ImageRow from "../components/content-rows/ImageRow";
import ContactPerson from "@/components/ContactPerson";
import ProjectSlider from "@/components/ProjectSlider";
import NewsSlider from "@/components/NewsSlider";
import { JSONAPI } from "@/etagen/jsonapi";

export default {
  name: "Taxonomy",
  metaInfo() {
    const title = this.title;
    const meta = this.meta;
    return { title: title, meta: meta };
  },
  components: { NewsSlider, ProjectSlider, ContactPerson, WrapperRow, ImageRow, TextRow },
  props: {
    data: Object,
    isRoot: Boolean,
    tags: String,
    entityType: String,
    uuid: String
  },
  data() {
    return {
      loading: false,
      error: null,
      content: null,
      rendered: null,
      associated: null,
      rawResult: null,
      references: null,
      contactPerson: null,
      news: null,
      title: "",
      meta: [],
      momentFormat: "HHmmssSSS",
      moments: [],
      sortedMoments: []
    };
  },
  watch: {
    $route: "fetchData"
  },
  created() {
    this.fetchData();
  },
  methods: {
    getBackgroundImageStyleAttr(url) {
      return `background-image: url("${url}");`;
    },
    updateMetaTags() {
      let metatags = [];
      if (this.content !== null && this.content.metatagsModule !== undefined) {
        for (let metatagsKey in this.content.metatagsModule) {
          if (this.content.metatagsModule[metatagsKey]["html_tag"] === "meta") {
            let mt_ = {};
            for (let k in this.content.metatagsModule[metatagsKey]) {
              if (k !== "html_tag") {
                mt_[k] = this.content.metatagsModule[metatagsKey][k];
              }
            }

            metatags.push(mt_);
          }
        }
      }

      this.meta = metatags;
      //console.debug(`Function Taxonomy.methods.updateMetaTags took ${(end - start) / 1000} seconds to complete.`);
    },
    determineTerms() {
      let terms = "";
      if (this.entityType === "taxonomy_term") {
        terms = this.content.title;
      } else if (this.tags !== "") {
        terms = this.tags;
      } else {
        terms = "all";
      }
      return terms;
    },
    fetchReferences() {
      JSONAPI.fetchReferencesFilteredByTag(this.determineTerms())
        .then(references => {
          this.references = references;
          //console.debug(`Function Taxonomy.methods.fetchReferences took ${(end - start) / 1000} seconds to complete.`);
        })
        .catch(error => console.error(error));
    },
    fetchNews() {
      JSONAPI.fetchArticlesFilteredByTag(this.determineTerms())
        .then(news => {
          this.news = news;
          //console.debug(`Function Taxonomy.methods.fetchNews took ${(end - start) / 1000} seconds to complete.`);
        })
        .catch(error => console.error(error));
    },
    fetchContactPersons() {
      JSONAPI.fetchContactPersonFilteredByTag(this.determineTerms())
        .then(result => {
          this.contactPerson = result;
          // console.debug(
          //   `Function Taxonomy.methods.fetchContactPersons took ${(end - start) / 1000} seconds to complete.`
          // );
        })
        .catch(error => console.error(error));
    },
    fetchData() {
      this.content = null;
      this.rendered = null;
      this.associated = null;
      this.rawResult = null;
      this.references = null;
      this.contactPerson = null;
      this.news = null;
      this.title = "";
      this.meta = [];

      this.content = null;
      this.error = this.post = null;
      this.loading = true;
      let alias = "";

      for (let k in this.$route.params) {
        if (alias.length !== 0) {
          alias += "|";
        }
        alias += this.$route.params[k];
      }
      alias = alias.replace(/\//g, "|");

      if (alias.match(/taxonomy\/term/)) {
        alias = alias.replace(/taxonomy\/term/g, "taxonomy_term");
      }

      EtagenDrupalApi.resolveAlias(alias)
        .then(result => {
          this.rawResult = result;
          if (result.status === "error") {
            this.content = null;
            this.associated = null;
            this.loading = false;

            this.displayError(result.message);
          }

          if (result.status === "OK") {
            EtagenDrupalApi.getByArgs("default", result.data.entity_type, "all", "all", result.data.id)
              .then(result => {
                this.loading = false;
                this.content = result.data[0].content;

                this.title = this.content.title;

                this.updateMetaTags();
                this.fetchContactPersons();
                this.fetchReferences();
                this.fetchNews();
                //console.debug(`Function Taxonomy.methods.fetchData took ${(end - start) / 1000} seconds to complete.`);
              })
              .catch(error => {
                this.content = null;
                this.loading = false;
                this.associated = null;
                this.displayError(error);
              });
          }
        })
        .catch(error => {
          this.displayError(error);
        });
    },
    displayError(message) {
      //console.error(message);
      this.$bvToast.toast(message, {
        title: "Fehler!",
        variant: "danger",
        solid: true,
        autoHideDelay: 5000
      });
    },
    showSeoText() {
      let text = document.querySelector(".seo .text-row-content");
      let btn = document.querySelector(".seo .see-more");
      text.classList.add("show");
      btn.classList.add("hide");
    }
  }
};
</script>

<style scoped lang="scss">
.dbg-sep {
  border: 1px solid red;
  padding: 10px;
  margin: 10px;
}
.see-more {
  background: url("../assets/plus.svg") no-repeat;
  transform: translateY(-50%);
}
</style>
