<!--
  WrapperRow.vue
  Component: WrapperRow

  This component includes every other component that is being needed.
  For details, read the documentation some levels deeper.

  @TODO: Test, test, test.
-->
<template>
  <div class="paragraph fp-section fp-table" v-if="data.paragraph !== undefined && isRoot == true">
    <div v-for="(row, index) in data.paragraph" :key="index" class="wrapper-row">
      <div
        v-if="row.type !== 'wrapper'"
        class="wrapper-row-inner max-w col"
        :class="colWidth + ' ' + nsbSectionClass"
        :style="innerStyle"
      >
        <TextRow v-if="row.type === 'text'" :data="row"></TextRow>
        <ImageRow v-if="row.type === 'image'" :data="row"></ImageRow>
        <SliderRow v-if="row.type === 'slider'" :data="row"></SliderRow>
        <TeaserRow v-if="row.type === 'teaser'" :data="row"></TeaserRow>
        <FormRow v-if="row.type === 'webform'" :data="row"></FormRow>
        <MediaRow v-if="row.type === 'media'" :data="row"></MediaRow>
      </div>
      <WrapperRow
        v-if="row.type === 'wrapper'"
        :data="row"
        :is-root="false"
        class="d-flex nsb-section flex-row"
      ></WrapperRow>
    </div>
  </div>

  <div class="wrapper-row-inner" v-else-if="data.paragraph !== undefined && isRoot == false">
    <div
      v-for="(row, index) in data.paragraph"
      :key="index"
      :class="colWidth + ' ' + nsbSectionClass"
      :style="innerStyle"
    >
      <TextRow v-if="row.type === 'text'" :data="row"></TextRow>
      <ImageRow v-if="row.type === 'image'" :data="row"></ImageRow>
      <SliderRow v-if="row.type === 'slider'" :data="row"></SliderRow>
      <TeaserRow v-if="row.type === 'teaser'" :data="row"></TeaserRow>
      <FormRow v-if="row.type === 'webform'" :data="row"></FormRow>
      <MediaRow v-if="row.type === 'media'" :data="row"></MediaRow>
    </div>
  </div>
</template>

<script>
import TeaserRow from "./TeaserRow";
import FormRow from "./FormRow";
import SliderRow from "./SliderRow";
import ImageRow from "./ImageRow";
import MediaRow from "./MediaRow";
import TextRow from "./TextRow";
import { Etagen } from "@/etagen";

export default {
  name: "WrapperRow",
  components: { TeaserRow, FormRow, SliderRow, ImageRow, TextRow, MediaRow },
  props: {
    data: Object,
    isRoot: Boolean,
    isSection: Boolean
  },
  data() {
    return {
      backgroundImage: "",
      backgroundColor: "",
      width: "",
      style: "",
      innerStyle: "",
      contentStyle: "",
      colWidth: "",
      rootClass: "",
      taxonomyContentText: "",
      nsbSectionClass: "",
      wrapperClass: "",
      rowItems: ""
    };
  },
  watch: {
    data: "buildWrapper"
  },
  methods: {
    buildWrapper() {
      if (this.isRoot) {
        this.nsbSectionClass = "nsb-section";
      }
      if (!this.isRoot) {
        if (this.data.backgroundColor !== undefined) {
          this.backgroundColor = Etagen.renderer.setBackgroundColor(this.data.backgroundColor);
        }

        if (this.data.backgroundImage !== undefined) {
          this.backgroundImage = Etagen.renderer.buildBackgroundImageStyle(this.data.backgroundImage.url);
          this.style += this.backgroundImage;
          this.backgroundColor = Etagen.renderer.getTransparentBackgroundColor(this.backgroundColor);
        }

        if (this.data.width !== undefined) {
          this.width = Etagen.renderer.setContentWidth(this.data.width);
        }

        let itemsPerRow = this.data.itemsPerRow;
        this.rowItems = `items-in-a-row-${itemsPerRow}`;
        itemsPerRow = parseInt(itemsPerRow);
        let colWidth = 12 / itemsPerRow;
        if (this.data.width === "wide") {
          this.colWidth = `p-0 col-lg-${colWidth}`;
        } else {
          this.colWidth = `col-lg-${colWidth}`;
        }
      }

      if (this.data.field_content_paragraphs !== undefined) {
        this.data.paragraph = this.data.field_content_paragraphs;
      } else if (this.data.contentRows !== undefined) {
        this.data.paragraph = this.data.contentRows;
      }

      if (!this.isRoot) {
        //console.log(this.data);
      }

      this.rootClass = this.isRoot ? 'root' : 'not-root';
    },
    mounted() {
      var container = document.querySelector('.paragraph');
      if(container.children.length > 1){
        container.classList.add('multiple-content');
      }
    }
  },
  created() {
    this.buildWrapper();
  }
};
</script>
