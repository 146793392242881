<template>
  <div id="proxy">
    <Taxonomy
      v-if="tags !== null && uuid !== null && entity_type !== null"
      :tags="tags"
      :uuid="uuid"
      :entity-type="entity_type"
    ></Taxonomy>
  </div>
</template>
<script>
import Taxonomy from "@/views/Taxonomy";
import { EtagenDrupalApi } from "@/etagen-api";
export default {
  name: "Proxy",
  components: { Taxonomy },
  data() {
    return {
      entity_type: null,
      tags: null,
      uuid: null
    };
  },
  watch: {
    $route: "updateData"
  },
  methods: {
    updateData() {
      let alias = "";
      for (let k in this.$route.params) {
        if (alias.length !== 0) {
          alias += "|";
        }
        alias += this.$route.params[k];
      }
      alias = alias.replace(/\//g, "|");

      if (alias.match(/taxonomy\|term/gi)) {
        alias = alias.replace(/taxonomy\|term/g, "taxonomy_term");
      }

      //console.warn(alias);

      EtagenDrupalApi.resolveAlias(alias)
        .then(result => {
          if (result.status === "error") {
            this.content = null;
            this.loading = false;
            //console.error(result);
          }

          this.tags = "";

          if (result.status === "OK") {
            //console.warn(result);

            if (result.data.tags !== undefined) {
              this.tags = result.data.tags;
            }

            if (result.data.uuid !== undefined) {
              this.uuid = result.data.uuid;
            }

            if (result.data.entity_type) {
              this.entity_type = result.data.entity_type;
            }
          }
        })
        .catch(() => {
          //console.error(error);
        });
    }
  },
  created() {
    this.updateData();
  }
};
</script>
