<template>
  <div class="project-slider">
    <div id="splide" class="splide">
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">
          <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 28 15.3"
            style="enable-background:new 0 0 28 15.3;"
            xml:space="preserve"
          >
            <g id="Gruppe_455" transform="translate(-947.5 -911.793)">
              <rect x="947.5" y="918.5" width="25.5" height="2" />
              <polygon points="967.8,927.1 966.4,925.7 972.6,919.5 966.4,913.2 967.8,911.8 975.5,919.5 	" />
            </g>
          </svg>
        </button>
        <button class="splide__arrow splide__arrow--next">
          <svg
            version="1.1"
            id="Ebene_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 28 15.3"
            style="enable-background:new 0 0 28 15.3;"
            xml:space="preserve"
          >
            >
            <g id="Gruppe_455" transform="translate(-947.5 -911.793)">
              <rect x="947.5" y="918.5" width="25.5" height="2" />
              <polygon points="967.8,927.1 966.4,925.7 972.6,919.5 966.4,913.2 967.8,911.8 975.5,919.5 	" />
            </g>
          </svg>
        </button>
      </div>
      <div class="splide__track">
        <ul class="splide__list">
          <li v-for="(group, groupKey) in projects" :key="groupKey" class="splide__slide">
            <div v-for="(ref, refKey) in group" :key="refKey" class="slide" :class="'slide-' + refKey">
              <a :href="ref.path">
                <div v-lazyload class="image">
                  <img :data-url="ref.teaserImage" :alt="ref.title" />
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Splide from "@splidejs/splide";
import Grid from "@splidejs/splide-extension-grid";
export default {
  name: "ProjectSlider",
  props: {
    projects: Array
  },
  created() {
    window.setTimeout(() => {
      new Splide("#splide", {
        type: "loop",
        perPage: 1,
        perMove: 1,
        pagination: false
      }).mount({ Grid });
    }, 100);
  }
};
</script>

<style scoped lang="scss">
.splide__slide {
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas:
    "box1 box2 box2 box3"
    "box1 box4 box5 box5";
  grid-gap: 0.7em;
  margin-right: 32.3em;

  max-height: 60vh;

  .slide {
    display: block;
    position: relative;

    .image {
      filter: grayscale(100%);
      transition: all 0.2s;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        filter: grayscale(0);
        img {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
    }
  }
  .slide-0 {
    grid-area: box1;
  }

  .slide-1 {
    grid-area: box2;
  }

  .slide-2 {
    grid-area: box3;
  }

  .slide-3 {
    grid-area: box4;
  }

  .slide-4 {
    grid-area: box5;
  }
}

.splide__arrow--prev{
  left: 2.8em;
}
.splide__arrow--next {
  right: 0.8em;
}
@media screen and (max-width: 450px){
  .splide__arrow--prev{
    left: 1.8em;
  }
  .splide__arrow--next {
    right: 1.8em;
  }
}
@media screen and (max-width: 1000px) {
  .splide__slide {
    grid-template-columns: auto;
    grid-template-rows: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    grid-template-areas:
      "box1 box2"
      "box1 box3"
      "box4 box5";
    grid-gap: 0.7em;
    margin-right: 2.1em;
    min-height: 65vh;
  }
}
</style>
